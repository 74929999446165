export const MenuItems = [
    {
        title: 'Who We Are',
        url: '#Home',
        cName: 'nav-links'
    },
    {
        title: 'What We Do',
        url: '#About',
        cName: 'nav-links'
    },
    {
        title: 'Event Schedule',
        url: '#Event',
        cName: 'nav-links'
    },
    {
        title: 'Gallery',
        url: '#Gallery',
        cName: 'nav-links'
    },

]
