import React, { useState, useEffect } from 'react';
import { MenuItems } from './MenuItems';
import './Navbar.css'

function Navbar(){
    const [navbar, setNavbar] = useState(false);
    const [menu, setMenu] = useState(false);

    const changeNavbarBackGround = () => {
        if (window.scrollY >= 90) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    const changeMenuState = () => {
        setMenu(!menu);
    }


    window.addEventListener('scroll', changeNavbarBackGround);

    return(
        <>
            <nav className={navbar ? "navbar navbar-active" : "navbar"}>
                <a href="#" className="name-logo">Dragon Phoenix</a>
                {/* <ul>
                    <li><a href="#Home">Who We Are</a></li>
                    <li><a href="#About">What We Do</a></li>
                    <li><a href="#Gallery">Gallery</a></li>
                    <li><a href="#">Services</a></li>
                    <li><a href="#">Contact</a></li>
                </ul>
                <div className="menu-icon" onClick={changeMenuState}>
                    <i className={menu ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div> */}
            </nav>
        </>
    );
    

}

export default Navbar

