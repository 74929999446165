import React, { useState, useEffect } from 'react';
import './ContactInfo.css'

function ContactInfo() {
    return(
        <div className="contact-info">
                <ul>
                    <li className="email">
                        dragonphoenixliondance@gmail.com
                    </li>

                    <li className="phone">
                        (714) 837-9364
                    </li>

                    <li className="locate">
                        Orange County, CA
                    </li>
                </ul>


                <hr />

                <p>At Dragon Phoenix, we strive to bring the best quality performance. </p>
                

            
        </div>
    );
}

export default ContactInfo
