import React, { useState, useEffect } from 'react';
import './About.css'
import teamphoto from "../photos/team.JPG"
import logo from "../photos/DragonPhoenix_Logo.png"

function About() {
        return(
            <div class="row">
                <div class="col-sm-12 col-md-5 col-xl-5 frame">
                    <img class="photo" src={teamphoto}/>
                </div>

                <div class="who-we-are col-sm-12 col-md-7 col-xl-7">
                    <p class="about-us">The Dragon Phoenix Lion Dance Troup is a California, Orange County, based team. We are an energetic, innovative, and professional group performing traditional lion dance. Let us bring luck, joy, and prosperity to any of your special occasions; new year festivities, weddings, birthdays, grand openings, or any momentous event. We will do our best to accommodate your special dates and bring you an exciting show of lions dancing to the heartbeat of the drums.
                    </p>

                    <p class="about-us">Our team is made up passionate dancers who take great pride in being able to share these ancient traditions with out audience. We strive to bring a sense of excitement and wae to every performance and to educate others about the cultural significant of lion dance.
                    </p>
                    
                    <hr class="separator-line"/>

                    <p class="follow-us">Follow us on Facebook & Instagram for our activities and schedules. </p>


                    <ul class="social-media">
                        <li> <a href="https://www.facebook.com/profile.php?id=100089961752014" class="fa fa-facebook"> </a> </li>
                        <li> <a href="https://www.instagram.com/dragon_phoenix_ld/?hl=en" class="fa fa-instagram"> </a> </li>
                        <li> <a href="https://www.youtube.com/channel/UCUpzVjg909TfVTdsUHte2Kw" class="fa fa-youtube"> </a> </li>
                    </ul>
                </div>
                
            </div>
        );
}

export default About
