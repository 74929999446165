import React, { useState, useEffect } from 'react';
import './Gallery2.css'
import mountain from '../mountain.jpg'
import image1 from "../photos/lion1.JPG"
import image2 from "../photos/lion2.JPG"
import image3 from "../photos/lion3.jpg"
import image4 from "../photos/lion4.jpg"
import image5 from "../photos/lion5.jpg"



import image11 from "../photos/lion11.JPG"

function Galery2() {
        return(

            <div class="gallery">
                <div>
                    <img src={image1}/>
                </div>

                <div class="v-stretch">
                    <img src={image2}/>
                </div>

                <div class="v-stretch">
                    <img src={image3}/>
                </div>

                <div class="v-stretch">
                    <img src={image4}/>
                </div>

                <div class="v-stretch">
                    <img src={image5}/>
                </div>


            </div>
        );
}

export default Galery2