import React, { useState, useEffect, useRef } from 'react';
import './Form.css'
import emailjs from '@emailjs/browser';


function Form() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_kfsfhb2', 'template_2ocpl4v', form.current, 'OyFPln4gzEHaR4KrK')
          .then((result) => {
              console.log(result.text);
              console.log("message sent!");
              // Pop up success & thank you dialog
              // clear data
          }, (error) => {
              console.log(error.text);
              console.log("message not send.");
              // Pop up fail dialog
          });
      };

    return(
        <form ref={form} onSubmit={sendEmail} className="contact_form row">
            <div class="child col-md-12">
                <input class="form-control" type="text" name="user_name" id="inputEmail4" placeholder="Your Name"/>
            </div>
            <div class="child form-group col-md-12">
                <input class="form-control" type="email" name="user_email" id="inputEmail4" placeholder="Email"/>
            </div>

            <div class="child col-md-12">
                <input class="form-control" type="text" id="inputEmail4" placeholder="Occasion"/>
            </div>
            
            <div class="child form-group col-md-12">
                <textarea class="form-control" name="message" id="exampleFormControlTextarea1" rows="5" placeholder="How can we help you?"/>
            </div>

            <div class="child form-group col-md-12">
                <button class="child btn btn-primary col-md-2" type="submit">Send it</button>
            </div>
        </form>
    );
}

export default Form
