import './App.css';
import Navbar from './components/Navbar/Navbar'
import Form from './components/Form'
import ContactInfo from './components/ContactInfo'
import About from './components/About'
import Gallery2 from './components/Gallery2'
import EmailSentDialog from './components/EmailSentDialog'
import {useInView} from 'react-intersection-observer';
import TeamHeader from './components/TeamHeader';
import 'bootstrap/dist/css/bootstrap.css';
import logo from "./photos/DragonPhoenix_Logo.png"

function App() {

  const { ref, inView, entry } = useInView();


  return (
    <div className="App">
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"></link>

      <Navbar></Navbar>
    

      <div className="intro-container">
        <div className="overlay"></div>
      </div>
      
    
      <div id="About">
        
        <div class="row about-section">

          <div class="about col-md-12">
            <About></About>
          </div>

          {/* <div class="logo-image">
            <img src={logo} alt=""/>
          </div> */}
        </div>
      </div>


      <div id="contact-section">

        
        <div class="row contact">
          <div class="col-md-12 col-lg-5">
            <Form></Form>
          </div>
          <div class="col-md-12 col-lg-7">
            <ContactInfo class="contact-info"></ContactInfo>
          </div>
        </div>
      </div>

      
      

      <div id="Gallery">
        <Gallery2></Gallery2>
      </div>

 


      {/* <div id="Recruit">
        <h1>Event</h1>
      </div> */}

    </div>

  );
}

export default App;
